//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import EntityCharts from "@/components/EntityCharts";
import CalendarMixin from "@/mixins/Calendar";
import RepricerMixin from "@/mixins/Repricer";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import WarningRepricer from "@/components/Alert/WarningRepricer.vue";
import { getEntityCellsConfig } from "@/utils/enums";
import HasTokens from "@/components/HasTokensRepricer.vue";
import ScriptsTable from "@/components/Repricer/ScriptsTable.vue";
import MyProductsTable from "@/components/Repricer/MyProductsTable.vue";
import ProductsManagementTable from "@/components/Repricer/ProductsManagementTable.vue";
import EventsLogTable from "@/components/Repricer/EventsLogTable.vue";
export default {
  mixins: [CalendarMixin, RepricerMixin],
  meta: {
    title: "Репрайсер - Обзор показателей"
  },
  data() {
    const localStorageCalendar = JSON.parse(localStorage.getItem("repricerCalendar"));
    if (localStorageCalendar) {
      this.$store.dispatch("repricer/updateCalendar", localStorageCalendar);
    }
    return {
      calendarInternal: null,
      calendar: this.$store.state.repricer.calendar,
      totalScripts: 0,
      limit: null
    };
  },
  created() {
    this.loadRepriceLimits();
  },
  methods: {
    async onAddRepriceLimit() {
      if (this.user.parent) {
        return;
      }
      await this.$modal.process(() => import("@/components/Billing/ModalPayment"), {
        type: "addLimit",
        preferredPlanId: 30,
        subscriptionAddLimit: this.currentSubscriptions[0],
        user: this.user
      });
    },
    async loadOverview() {
      var _total;
      let total = null;
      total = await this.$store.dispatch("repricer/getHighlights", this.reportSettings);
      let res = {};
      (_total = total) === null || _total === void 0 || _total.forEach(item => {
        res = {
          ...res,
          [item.id]: {
            total: Number(item.value).toFixed(2),
            dynamic: []
          }
        };
      });
      return res;
    },
    async loadRepriceLimits() {
      const {
        data
      } = await this.$store.dispatch("repricer/getRepricerLimit");
      this.limit = {
        count: data.sku_count,
        limit: data.sku_quota
      };
    },
    addScript() {
      this.$router.push({
        name: "RepricerScript"
      });
    },
    updateTotal(val) {
      this.totalScripts = val;
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    currentSubscriptions() {
      return this.$store.state.billing.currentSubscriptions;
    },
    cells() {
      return this.$store.state.repricer.marketplace.title === "Wildberries" ? getEntityCellsConfig("repricerWb") : getEntityCellsConfig("repricerOzon");
    },
    reportSettings() {
      const tokens = this.selectedTokens.map(item => item.id);
      if (!this.calendar) {
        return null;
      }
      const dates = [this.calendar[0], this.calendar[1]];
      return {
        mp: this.marketplace.id,
        date: dates[0],
        date2: dates[1],
        tokens,
        page_size: 10
      };
    },
    reportSettingsNoDates() {
      const result = {
        ...this.reportSettings
      };
      delete result.date;
      delete result.date2;
      return result;
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("repricer/updateCalendar", this.calendar);
      localStorage.setItem("repricerCalendar", JSON.stringify(this.calendar));
    }
  },
  components: {
    MainTitle,
    EntityCharts,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    WarningRepricer,
    HasTokens,
    DataLoading,
    ScriptsTable,
    MyProductsTable,
    ProductsManagementTable,
    EventsLogTable
  }
};