//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { toExportFormat } from "@/utils/export";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prev: null
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 10000;
        query.page = 1;
      }
      let result = await this.$store.dispatch("repricer/getEventsLog", {
        ...query,
        ...this.reportSettings
      });
      if (query.action === "export") {
        return toExportFormat(result, this.columns);
      } else {
        result = {
          items: [...result.data.map(item => {
            return {
              ...item,
              productImage: `https://salesfinder.ru/api/getProductImage?mp=${this.reportSettings.mp}&sku=${item.sku}`
            };
          })],
          total: result.totals.total,
          success: result.success,
          message: result.message
        };
      }
      return result;
    }
  },
  computed: {
    columns() {
      return [{
        title: "SKU",
        show: true,
        name: "sku",
        width: 100,
        filter: "text"
      }, {
        title: "Товар",
        show: true,
        name: "product",
        type: "text",
        width: 300,
        getImage: item => item.productImage
      }, {
        title: "Дата и время",
        show: true,
        name: "date_add",
        type: "datetimeLocal",
        width: 160
      }, {
        title: "Сценарий",
        show: true,
        name: "script_name",
        width: 160,
        filter: "text"
      }, {
        title: "Тип",
        show: true,
        name: "script_type_name",
        type: "text",
        width: 160,
        filter: "text"
      }, {
        title: "Событие",
        show: true,
        name: "event",
        type: "text",
        width: 240,
        filter: "text"
      }, {
        title: "Старая цена",
        show: true,
        name: "last_price",
        type: "text",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Новая цена",
        show: true,
        name: "current_price",
        type: "text",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Маркетплейс",
        show: true,
        name: "datasource",
        type: "text",
        width: 140,
        filter: "text"
      }, {
        title: "Магазин",
        show: true,
        name: "store_name",
        type: "text",
        width: 140,
        filter: "text"
      }];
    }
  }
};